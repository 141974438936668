/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

$theme-colors: (
   primary: #1c2d44,
   secondary: #3e5c76,
   tertiary: #748baa,
   accent: #fba72f,
);
@import "../node_modules/bootstrap/scss/bootstrap.scss";

@import "assets/css/screen.css";
@import "assets/css/login-form.css";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


/* This is for the admin check box on the add-contact.html dialog. It needs to be
   in the top level style sheet to override the material styles for some reason. */
.adminCheckbox .mat-checkbox.mat-accent {
   .mat-checkbox-inner-container {
      margin: 2px 8px auto 0;
   }
}

/* This is for the admin check box in the user list on the user-details page. It needs to be
   in the top level style sheet to override the material styles for some reason. */
.mat-column-admin .mat-checkbox.mat-accent .mat-checkbox-inner-container {
   margin: 10px auto auto auto;
}


.qrImg {
   margin-left: 45px;
}

a {
   /* color: #021c72; */
   font-weight: 400;
   text-decoration: none;
 }