@charset "UTF-8";

:root {
  --primary-color: #1c2d44;
  --secondary-color: #3e5c76;
  --tertiary-color: #748baa;
  --accent-color: #fba72f;
}

.plans,
.staff,
.contact-box,
footer .footer-columns {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}

html {
  background: #2b2b40;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  font-family: "San Francisco", "Helvetica Neue", "Helvetica", "Arial";
}

a {
  color: #00a4ca;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

img {
  width: 100%;
}

h1 strong,
h2 strong {
  font-weight: 700;
}

h1 {
  font-weight: 300;
  font-size: 2.3em;
  margin: 0;
}

h2 {
  font-weight: 300;
  font-size: 2.2em;
  margin: 0 0 30px 0;
}

h3 {
  margin: 20px 0 10px 0;
}

p,
address {
  font-size: 1.38em;
  color: #666;
  margin-bottom: 20px;
  font-weight: 300;
  line-height: 1.4em;
}

.bottom-cta {
  background: linear-gradient(to bottom, #748baa 0%, #748baa 100%);
  color: #fff;
  text-align: center;
  margin: 0;
  padding: 50px 0 50px 0;
}

.bottom-cta h2 {
  margin-bottom: 50px;
}

.testimonial {
  background: #f5f5f5;
  margin: 0;
  padding: 100px 0;
}

.testimonial .testimonial-block {
  max-width: 750px;
  width: 98%;
  margin: 0 auto;
}

@media (min-width: 450px) {
  .testimonial .testimonial-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .testimonial .testimonial-block blockquote {
    -webkit-flex: 1;
    flex: 1;
  }
}

.hero {
  color: #ffffff;
  text-align: center;
  background: linear-gradient(to bottom, #1c2d44 0%, #3e5c76 100%) no-repeat #1c2d44;
  padding-top: 50px;
}

.hero p {
  color: #fff;
}

@media (min-width: 768px) {
  .flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .flex .text,
  .flex .image {
    -webkit-flex: 1;
    flex: 1;
    padding: 0 20px;
  }
  .content section:nth-child(even) .flex {
    flex-direction: row-reverse;
  }
}

.container,
.text-container {
  margin: 0 auto;
  position: relative;
  padding: 0 20px;
}

.text-container {
  max-width: 950px;
}

.container {
  max-width: 1140px;
}

.container.max-container {
  max-width: 100%;
  padding: 0;
}

.container.min-container {
  width: 740px;
}

.main-content {
  min-height: 800px;
}

header {
  color: #fff;
  padding: 20px 0;
  background: #1c2d44; /* Old browsers background: linear-gradient(to bottom, #1c2d44 0%, #4075b5 100%) no-repeat #4379B9; */
}

header a {
  color: #fff;
  text-decoration: none;
  z-index: 1;
  position: relative;
}

header a:hover {
  text-decoration: none;
}

header .company-name {
  font-size: 1.7em;
  line-height: 0;
}

header .company-name a {
  display: inline-block;
  margin-top: -5px;
}

header .company-name div {
  display: inline-block;
  height: 60px;
  width: 311px;
  background: url(../img/cassini_logo_sprite.png) -10px -90px;
}
header .company-name div:hover {
  background: url(../img/cassini_logo_sprite.png) -10px -10px;
  cursor: pointer;
}

.content {
  background: #fff;
  padding: 1px 0 0 0;
  position: relative;
}

.screenshot {
  max-width: 100%;
  height: auto;
  display: block;
  box-shadow: 0 1px 0 #ccc, 0 1px 0 1px #eee;
  border-radius: 2px;
  margin-left: auto;
  margin-right: auto;
  background: #ddd
    4px 4px no-repeat;
  padding: 20px 0 0 0;
  position: relative;
}

section {
  padding: 100px 0;
}

section + section {
  padding-top: 0;
}

.subtext {
  margin-top: 10px;
  text-align: center;
}

.cta {
  margin: 60px 0;
}

.page h2 {
  text-align: center;
}

blockquote {
  padding: 18px 25px;
  margin: 0;
  quotes: "“" "”" "‘" "’";
  font-style: italic;
}

blockquote .author {
  display: block;
  font-weight: bold;
  margin: 10px 0 0 0;
  font-size: 0.85em;
  font-style: normal;
}

blockquote p {
  display: inline;
}

blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

.square-image {
  width: 150px;
  height: 150px;
  overflow: hidden;
  margin: 25px auto 0 auto;
  position: relative;
  border-radius: 200px;
}

.square-image img {
  position: absolute;
  left: -1000%;
  right: -1000%;
  top: -1000%;
  bottom: -1000%;
  margin: auto;
  width: 300px;
}

.page {
  margin-bottom: 0;
  padding-bottom: 80px;
}

.center-text {
  text-align: center;
}

.editor-link {
  display: none;
  margin-top: 0;
}

.editor-link .btn {
  border: 0;
  border-radius: 2px;
  width: 100%;
  max-width: 500px;
  box-sizing: border-box;
  font-size: 2rem;
  text-decoration: none;
  padding: 10px 15px;
  margin: 0;
  font-size: 18px;
  cursor: pointer;
  background-color: #f7e064;
  color: #333;
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.editor-link .btn:hover {
  background-color: #f4d525;
  color: #333;
}

.plans {
  -webkit-flex-flow: wrap;
  -moz-flex-flow: wrap;
  -ms-flex-flow: wrap;
  flex-flow: wrap;
  padding: 50px 0 30px 0;
}

.plans .plan {
  list-style: none;
  position: relative;
  padding: 0;
  margin: 0 10px 50px 10px;
  text-align: center;
  border: 1px solid #eee;
  border-radius: 5px;
  box-shadow: 0px 0px 10px #eee;
  width: 100%;
  height: 500px;
}

.plans .plan .price {
  font-size: 1.4em;
}

.plans .plan .price span {
  font-size: 0.8em;
}

.plans .plan .pricing-cta {
  padding: 0;
  height: 70px;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.plans .plan .pricing-cta .button.alt {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 50px;
}

.plans .plan .pricing-cta a {
  display: block;
  box-sizing: border-box;
  padding: 10px 0;
  border-radius: 5px;
  border-color: white;
  font-size: 1.1em;
}

@media (min-width: 768px) {
  .plans .plan {
    -webkit-flex: 1;
    flex: 1;
  }
}

.plans .plan li {
  padding: 20px 0;
  font-weight: 400;
  font-size: 1.2em;
}

.plans .plan.smallplan li:first-of-type {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  background-color: var(--tertiary-color);
}
.plans .plan.smallplan li:last-of-type {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: var(--tertiary-color);
}

.plans .plan.largeplan li:first-of-type {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  background-color: var(--secondary-color);
}
.plans .plan.largeplan li:last-of-type {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: var(--secondary-color);
}

.plans .plan.enterpriseplan li:first-of-type {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  background-color: var(--primary-color);
}
.plans .plan.enterpriseplan li:last-of-type {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: var(--primary-color);
}

.plans .plan .endpoint-costs {
  padding: 0px 20px;
  text-align: center;
  list-style-type: none;
}

.plans .plan .endpoint-costs li.endpoint-cost-item {
  font-weight: 400;
  font-size: 0.9em;
  background-color: white;
  padding: 0 10px;
  margin: auto;
}

.plans .plan li h3 {
  padding: 0;
  margin: 0;
  color: #fff;
  font-weight: normal;
}

.faq {
  color: #666;
  font-size: 1.38em;
}

@media (min-width: 768px) {
  .faq {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
  }
}

.faq div {
  break-inside: avoid;
  padding: 25px 0;
}

.faq dt {
  font-weight: 500;
  margin: 0 0 5px 0;
}

.faq dd {
  font-weight: 300;
  padding: 0;
  margin: 0;
}


.case-studies {
  color: #666;
  font-size: 1.38em;
}

.case-studies div {
  break-inside: avoid;
  padding: 25px 0;
}

.case-studies dt {
  font-weight: 500;
  margin: 0 0 5px 0;
}

.case-studies dd {
  font-weight: 300;
  padding: 0;
  margin: 0;
}



.staff {
  padding: 0;
  list-style: none;
  -webkit-flex-flow: wrap;
  -moz-flex-flow: wrap;
  -ms-flex-flow: wrap;
  flex-flow: wrap;
  text-align: center;
}

.staff li {
  padding: 30px 20px;
  box-sizing: border-box;
  width: 100%;
}

@media (min-width: 450px) {
  .staff li {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 45%;
    -moz-box-flex: 1;
    -moz-flex: 1 1 45%;
    -ms-flex: 1 1 45%;
    flex: 1 1 45%;
  }
}

@media (min-width: 768px) {
  .staff li {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 29%;
    -moz-box-flex: 1;
    -moz-flex: 1 1 29%;
    -ms-flex: 1 1 29%;
    flex: 1 1 29%;
  }
}

.staff .square-image {
  width: 200px;
  height: 200px;
}

.staff .square-image img {
  border-radius: 200px;
}

.staff .name {
  font-size: 1.3em;
  margin-top: 20px;
}

.staff .name a {
  color: #2867b2;
}

.staff .name a span {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-left: 5px;
  margin-bottom: -3px;
  background-image: url(../img/LI-In-Bug.png);
  background-size: cover;
}

.staff .position {
  color: #666;
}

.map {
  width: 100%;
  margin: 100px 0;
  height: 400px;
}

.contact-box {
  -webkit-flex-flow: wrap;
  -moz-flex-flow: wrap;
  -ms-flex-flow: wrap;
  flex-flow: wrap;
  max-width: 750px;
  margin: 0 auto;
}

.contact-box form {
  width: 100%;
}

.contact-box p {
  margin: 0;
}

.contact-box .contact-form,
.contact-box .contact-details {
  margin: 20px 30px 20px 30px;
}

.contact-details button {
  margin-top: 15px;
  width: 100px;
}

@media (min-width: 768px) {
  .contact-box .contact-form,
  .contact-box .contact-details {
    -webkit-flex: 1;
    flex: 1;
  }
}

.button a,
input[type="submit"] {
  color: #fff;
  text-decoration: none;
  padding: 10px 30px;
  background: #4379b9;
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  transition: 0.2s ease-in-out;
}

.button a:hover,
input[type="submit"]:hover {
  border: 1px solid #fff;
  background: #042e5f;
  cursor: pointer;
}

.button.alt a {
  background: rgba(255, 255, 255, 0.15);
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 16px 50px;
}

.button.alt a:hover {
  background: #fff;
  color: #4379b9;
}

button.btn-cassini-primary {
  color: #fff;
  font-weight: 500;
  background-color: var(--primary-color);
  background-repeat: repeat-x;
  border-color: var(--primary-color);
}
button.btn-cassini-primary:hover {
  color: #fff !important;
  background-color: var(--secondary-color);
  background-repeat: repeat-x;
  border-color: var(--primary-color);
}

.nav-open nav {
  border-bottom: 1px dotted rgba(255, 255, 255, 0.2);
  padding: 10px 0;
}

.nav-open nav a {
  display: block;
}

@media (min-width: 620px) {
  .nav-open nav {
    border: 0;
    padding: 0 20px;
  }
  .nav-open nav a {
    display: inline;
  }
}

nav {
  text-transform: uppercase;
  font-size: 1em;
  width: 100%;
}

@media (min-width: 620px) {
  nav {
    text-align: right;
    position: absolute;
    top: 13px;
    right: 0;
    padding: 0 20px;
  }
}

nav a {
  margin: 0 3px;
  padding: 20px 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0);
  color: rgba(255, 255, 255, 0.8);
  transition: 0.2s ease-in-out;
  display: none;
}

@media (min-width: 620px) {
  nav a {
    display: inline;
    padding: 10px;
  }
}

nav a.nav-toggle {
  display: inline;
  position: absolute;
  right: 10px;
  top: -22px;
  font-size: 1.9em;
  border: 0;
}

@media (min-width: 620px) {
  nav a.nav-toggle {
    display: none;
  }
}

nav a.nav-toggle:hover {
  border: 0;
}

nav a:hover {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  color: #fba72f;
}

@media (min-width: 620px) {
  nav a.highlight {
    border: 1px #ccc solid;
    border-radius: 5px;
  }
  nav a.highlight:hover {
    background: #fff;
    color: #fba72f;
    border-color: #fba72f;
  }
}

nav a.active {
  color: #fba72f;
  text-decoration: none;
}

@media (min-width: 620px) {
  .subnav a {
    display: inline;
    padding: 10px;
  }
}

.subnav {
  border-bottom: 1px dotted rgba(255, 255, 255, 0.2);
  height: 60px;
  padding-top: 20px;
  margin-bottom: 15px;
  background-color: var(--secondary-color);
}
.subnav a {
  margin: 0 3px;
  padding: 20px 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0);
  color: white;
}
.subnav a:hover {
  color: var(--accent-color);
  text-decoration: none;
}
.subnav a:nth-of-type(1) {
  padding-left: 0px;
}

.footer-links {
  /*width: 100%;*/
  margin: 10px;
  padding: 0;
}

@media (min-width: 100px), (max-width: 150px) {
  .footer-links {
    -webkit-flex: 1 0 100px;
    flex: 1 0 100px;
  }
}

.footer-links li {
  list-style: none;
  margin: 15px auto;
}

@media (min-width: 100px) {
  .footer-links li {
    max-width: 150px;
  }
}

.footer-links li a:hover {
  text-decoration: none;
}

.footer-links li a svg {
  fill: #999;
  margin-right: 10px;
  transition: fill 0.2s ease;
  vertical-align: middle;
  position: relative;
  top: -2px;
  width: 22px;
  height: 22px;
}

.footer-links li a:hover svg {
  fill: #fff;
}

.footer-links li a.twitter-icon:hover svg {
  fill: #55acee;
}

.footer-links li a.google-plus-icon:hover svg {
  fill: #db4437;
}

.footer-links li a.youtube-icon:hover svg {
  fill: #cd201f;
}

.footer-links li a.instagram-icon:hover svg {
  fill: #f167f5;
}

.footer-links li a.linkedin-icon:hover svg {
  fill: #0077b5;
}

.footer-links li a.pinterest-icon:hover svg {
  fill: #bd081c;
}

.footer-links li a.rss-icon:hover svg {
  fill: #f26522;
}

footer {
  padding: 50px 0 50px 0;
  font-size: 1.1em;
  position: relative;
  background: #1c2d44;
  color: #fff;
}

footer .copyright {
  font-size: 1em;
  margin: 0 0 10px 0;
}

footer .iconcredit {
  font-size: 0.8em;
  margin: 0 auto;
}

@media (min-width: 450px) {
  footer .copyright {
    text-align: center;
  }
}

@media (min-width: 450px) {
  footer .iconcredit {
    text-align: center;
  }
}

footer,
footer a {
  color: #999;
}

footer h2 {
  font-size: 1.4em;
  margin: 30px 0;
  color: #ccc;
}

footer .footer-columns {
  -webkit-flex-flow: wrap;
  -moz-flex-flow: wrap;
  -ms-flex-flow: wrap;
  flex-flow: wrap;
  margin: -10px -10px 10px -10px;
}

footer a {
  text-decoration: none;
}

footer a:hover {
  color: #fff;
}

footer .legal-line {
  width: 100%;
  padding: 30px 0;
  margin: 0;
  background-color: #222527;
}

footer .legal-line a {
  font-weight: 600;
}

div.mat-expansion-panel-body p {
  font-size: medium;
}

div.mat-expansion-panel-body label {
  font-weight: 500;
}

mat-form-field.mat-form-field-400 {
  width: 400px;
}

mat-form-field.mat-form-field-600 {
  width: 600px;
}

mat-form-field.mat-form-field-800 {
  width: 800px;
}

mat-panel-title.feed-name {
  width: 300px;
}

mat-panel-description.feed-description {
  width: 100%;
}

.pdf-icon {
  top: 5px;
  left: 5px;
  width: 45px;
  margin: 0 10px 15px 0;
  display: block;
  float: left;
}

.casestudy {
  font-size: 1.2em;
  font-weight: 500;
  display: block;
  float: left;
  width: 450px;
}

.network-integration {
  width: 360px;
}

.about-cert-logo {
  margin: 0px auto 0px auto;
  display: block;
  width: auto;
}

.about-mfn-logo {
  margin: 0px auto 40px auto;
  display: block;
  width: 600px;
}

app-info p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #212529
}

app-info table {
  table-layout: auto;
  margin-bottom: 15px;
}

app-info table td {
  padding-bottom: 10px;
}

app-info table td.name-col {
  width: 90px;
  font-weight: 700;
  vertical-align: text-top;
}
/*# sourceMappingURL=screen.css.map */
